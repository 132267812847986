import React, { Component } from 'react'
import { Button, Modal } from 'antd'
import { Footer as FOOTER } from '~/styles'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import './style.css'
// const appVersion = process.env.REACT_APP_WEBAPP_VERSION
@injectIntl
class Footer extends Component {
  hardReload = () => {
    window.location.reload(true)
  }
  footerModal = () => {
    Modal.info({
      title: `version: ${process.env.REACT_APP_WEBAPP_VERSION}`,
      okText: this.props.intl.formatMessage({ id: 'app.back' }),
      maskClosable: true,
      content: (
        <div>
          <div>
            <Button className='hardReload' icon="reload" onClick={this.hardReload} >
              {this.props.intl.formatMessage({id: 'app.refresh'})}
            </Button>
          </div>
        </div>
      ),
      onOk () {}
    })
  }
  render () {
    // console.log(_get(process, 'env.REACT_APP_WEBAPP_VERSION'))
    return (
      <FOOTER>
        <div style={{ textAlign: 'center', marginTop: '20px', borderBottom: '1px solid rgba(255, 255, 255, 0.2)', paddingBottom: '10px' }} >
          {/* <img src="/images/white-thairun.png" alt="Thai.Run" style={{ height: '20px' }} /><br /> */}
          <img src="/white_logo2.png" alt="Thai.Run" style={{ height: '20px' }} /><br />
          { process.env.REACT_APP_PLATFORM === 'mice' || process.env.REACT_APP_PLATFORM === 'mas'  ?
          <div className="links" style={{ display:'flex',justifyContent:'center'  }} >
            <Link className="privacy-policy-footer" to="/privacy-policy">Policy</Link>
          </div> :
          <div className="links">
            <a className="outsideLink" href="https://vr.thai.run/">Virtual Run</a>
            <a className="outsideLink" href="https://race.thai.run/">สมัครงานวิ่ง</a>
            <a className="outsideLink" href="https://read.thai.run/">เรื่องเล่านักวิ่ง</a>
            <a className="outsideLink" href="https://photo.thai.run/">ซื้อภาพงานวิ่ง</a>
            <a className="outsideLink" href="https://shop.thai.run/">อุปกรณ์งานวิ่ง</a>
            <Link className="privacy-policy-footer" to="/privacy-policy">Policy</Link>
          </div>
          }
        </div>
        <div className="last-row" >
          <span id="copyright" className="last-line">Copyright © 2020</span>
          { process.env.REACT_APP_PLATFORM !== 'mice' &&
          <div id="social" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <a href="https://www.facebook.com/TriathlonMalaysia/" style={{ height: '35px' }} key="facebook">
              <svg id="facebookIcon" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                  <path d="M10.083 0C15.653 0 20.166 4.513 20.166 10.083C20.166 15.653 15.652 20.166 10.083 20.166C8.75881 20.1663 7.44754 19.9056 6.22409 19.399C5.00065 18.8924 3.889 18.1497 2.95266 17.2133C2.01631 16.277 1.27361 15.1654 0.766988 13.9419C0.260363 12.7185 -0.000262538 11.4072 1.98451e-07 10.083C1.98451e-07 4.513 4.513 0 10.083 0ZM10.083 1.575C8.96571 1.575 7.85937 1.79507 6.82713 2.22263C5.79489 2.6502 4.85698 3.27689 4.06694 4.06694C2.47138 5.6625 1.575 7.82654 1.575 10.083C1.575 12.3395 2.47138 14.5035 4.06694 16.0991C4.85698 16.8891 5.79489 17.5158 6.82713 17.9434C7.85937 18.3709 8.96571 18.591 10.083 18.591C12.3395 18.591 14.5035 17.6946 16.0991 16.0991C17.6946 14.5035 18.591 12.3395 18.591 10.083C18.591 8.96571 18.3709 7.85937 17.9434 6.82713C17.5158 5.79489 16.8891 4.85698 16.0991 4.06694C15.309 3.27689 14.3711 2.6502 13.3389 2.22263C12.3066 1.79507 11.2003 1.575 10.083 1.575ZM11.152 5.041L12.451 5.048V6.776H11.318C11.128 6.776 10.722 6.866 10.722 7.259V8.539H12.476L12.205 10.319H10.722V15.125H8.976V10.319H7.69V8.539H8.976V7.156C8.976 6.33 9.38 5.042 11.152 5.042V5.041Z" fill="white" />
                </mask>
                <g mask="url(#mask2)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M-0.916992 -0.916992H21.083V21.083H-0.916992V-0.916992Z" fill="white" />
                </g>
              </svg>
            </a>
          </div>
          }
          { process.env.REACT_APP_PLATFORM === 'mice' ?
          <span id="thairun-co" className="last-line">MICE & Communication Co., Ltd.</span>
          :
          <span id="thairun-co" className="last-line">Powered by Thaidotrun Co., Ltd.</span>
          }
        </div>
        {/* <Row className="bottomRow" type="flex" justify="start" >
          <Col xs={6} md={{ span: 3, offset: 0 }}>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img id="footerLogo" className="footer-logo" src="/logothairun-(W).png" alt="Thai.Run" />
            </Link>
          </Col>
          <Col xs={{ span: 16, offset: 1 }} md={{ span: 6, offset: 1 }} className="contactUsSection">
            <div style={{ lineHeight: 0.9, fontWeight: 'lighter', marginTop: '10px' }}>
              <span className="address">809 โครงการ Stadium One ซอย จุฬาลงกรณ์ 4 ถนน บรรทัดทอง แขวง วังใหม่ เขต ปทุมวัน กรุงเทพฯ 10330</span><br />
              <span className="address">race@thai.run</span>
            </div>
          </Col>
          <Col className="followUsSection" xs={{ span: 24, offset: 7 }} md={{ span: 4, offset: 1 }} >
            <div style={{marginTop: '10px'}}>
              <a href='https://www.facebook.com/thaidotrun/?epa=SEARCH_BOX' target="_blank" alt="Facebook" rel="noopener noreferrer" >
                <img src="/facebook.png" alt="Facebook" style={{ width: '10%', marginRight: '5px' }} className="footer-logo-facebook"/>
              </a>
              <a href='https://m.me/thaidotrun' target="_blank" alt="Facebook" rel="noopener noreferrer" >
                <img src="/messenger.png" alt="messenger" style={{ width: '10%' }} className="footer-logo-facebook"/>
              </a>
            </div>
            <div style={{marginTop: '10px'}}>
              <Link className="privacy-policy-footer" to="/privacy-policy">Privacy Policy</Link>
            </div>
          </Col>
        </Row> */}
      </FOOTER>
    )
    // <FooterContainerV2 style={{display: 'flex', justifyContent: 'flex-start'}}>
    //   <ImgLogoFooterV2>
    //     <img src="/logothairun-(W).png" alt="ImgLogoFooter" style={{ width: '100%', maxWidth: '130px', maxHeight: '70px', marginBottom: 10 }} className="footer-logo"/>
    //   </ImgLogoFooterV2>
    //   <ContactFooterV2>
    //     <h2 style={{ color: '#fff', fontWeight: 'bold', marginBottom: '0.1em' }}>CONTACT US</h2>
    //     <p style={{ color: '#fff' }}>809 โครงการ stadium One ซอย จุฬาลงกรณ์ 4 ถนน บรรทัดทอง แขวง วังใหม่ เขต ปทุมวัน กรุงเทพฯ 10330</p>
    //     <a href='https://www.facebook.com/thaidotrun/?epa=SEARCH_BOX' target="_blank" alt="Facebook" rel="noopener noreferrer" >
    //       <img src="/facebook.png" alt="Facebook" style={{ width: '10%', marginRight: '5px' }} className="footer-logo-facebook"/>
    //     </a>
    //     <a href='https://m.me/thaidotrun' target="_blank" alt="Facebook" rel="noopener noreferrer" >
    //       <img src="/messenger.png" alt="messenger" style={{ width: '10%' }} className="footer-logo-facebook"/>
    //     </a>
    //   </ContactFooterV2>
    // </FooterContainerV2>
  }
}

export default Footer
