import React, { Component } from 'react'
import {
  Form,
  // Input,
  Button,
  message,
  // Select,
  // DatePicker,
} from 'antd'
import { compose } from "recompose"
import { withApollo } from "@apollo/client/react/hoc"
import gql from 'graphql-tag'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { loginUser, registerUser } from '../../auth'
import {injectIntl } from 'react-intl'
import {
  TextField
  // MenuItem,
  // Select,
  // InputLabel, FormControl
} from '@material-ui/core'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
// import nationalities from '../../data/nationalities'
import './style.css'
// const dateFormat = 'DD/MM/YYYY'
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker
// } from '@material-ui/pickers'

const FormItem = Form.Item
// const Option = Select.Option
const currentUser = gql`
  query currentUser {
    currentUser {
      _id
      email
      profile {
        _id
        imageUrl
        firstName
        lastName
        birthDate
        gender
        nationality
      }
    }
  }
`
// function hasErrors (fieldsError) {
//   return Object.keys(fieldsError).some(field => fieldsError[field])
// }

const StyledTextField = styled(TextField)`
  label + .MuiInput-formControl {
    margin-top: unset;
  }
  .ant-form label {
    font-size: 14px;
    color: #bfbfbf;
    font-weight: bold;
  }
`

@inject('i18n')
@observer
@injectIntl
class RegisterForm extends Component {
  state = {
    signupLoading: false
  }

  checkPassword = (rule, value, callback) => {
    // const form = this.props.form
    if (value.length > 7) {
      callback()
    } else {
      callback('Your password is too short!')
    }
  }

  render () {
    const {
      getFieldDecorator
      // getFieldsError,
      // getFieldError,
      // isFieldTouched,
      // isFieldsTouched
    } = this.props.form
    const { i18n: { locale }, intl } = this.props
    // const {nationality} = currentUser
    const handleSignUp = e => {
      e.preventDefault()
      const { validateFields } = this.props.form
      this.setState({
        signupLoading: true
      })
      validateFields((err, values) => {
        // console.log('values', values)
        if (err) {
          console.error(err)
          this.setState({
            signupLoading: false
          })
          return
        }
        const {
          _id,
          email,
          password,
          profile: {
            birthDate,
            firstName,
            gender,
            lastName,
            nationality
          }
        } = values

        const bd =
        locale === 'th'
          ? moment(birthDate).subtract(543, 'year')
          : moment(birthDate)

        const newValues = {
          _id,
          email,
          password,
          profile: {
            birthDate: bd,
            firstName,
            gender,
            lastName,
            nationality
          }
        }
        registerUser(newValues).then(res => {
          if (res.error) {
            this.setState({
              signupLoading: false
            })
            return message.error(res.error.message, 10)
          }
          loginUser(newValues).then(res => {
            if (res.error) {
              this.setState({
                loginLoading: false
              })
              return message.error(res.error.message, 10)
            }
            // console.log('login response', res)
            this.props.client
              .query({
                query: currentUser,
                fetchPolicy: 'network-only'
              })
              .then(data => {
                this.setState(
                  {
                    loginLoading: false,
                    signupLoading: false
                  },
                  this.props.onClose
                )
                // console.log('state', this.props.location.state)
                const { from } = this.props.location.state || {
                  from: { path: '/dashboard' }
                }
                this.props.history.push(from)
                this.props.form.resetFields()
                window.scrollTo(0, 0)
                window.location.reload()
              })
          })
        })
        // TODO: display toast
      })
    }
    // const datePicklocale = {
    //   lang: {
    //     locale: 'th_TH'
    //   }
    // }
    // const birthDate = locale === 'th' ? moment().add(543, 'year') : moment()
    // const email = isFieldTouched('email') && getFieldError('email')
    // const UA = navigator.userAgent || navigator.vendor || window.opera

    // const isLINE = function (UA) {
    //   return (UA.indexOf('Line/') > -1)
    // }
    // let redirectTo = (isLINE(UA) ? (this.props.redirectTo || '') + '&&openExternalBrowser=1' : this.props.redirectTo) || ''
    // let redirectTo = this.props.redirectTo
    return (
      <Form onSubmit={handleSignUp} >
        {/* <a href={getAuthUri('facebook', { redirectTo })}>
          <Row className="facebookBox">
            <Col span={2} >
              <img src="/FacebookNew.png" style={{ width: '100%', maxWidth: '30px' }} alt="logo" />
            </Col>
            <Col span={22} className="facebookText">
              {intl.formatMessage({ id : "app.facebook.login"})}
            </Col>
          </Row>
        </a>
        <Divider style={{ marginTop: 50 }} /> */}
        {/* <div className="lineLoginTitle">
          <div className="loginModalTitle">
            <span>
              {intl.formatMessage({ id : "app.Or"})}
            </span>
          </div>
        </div> */}
        <div className="loginTextField">
          <FormItem hasFeedback>
            {getFieldDecorator('profile.firstName', {
              rules: [
                {
                  required: true,
                  message: 'Please input your first name!'
                },
                {
                  message: 'Please input your first name!'
                }
              ]
              // })(<Input placeholder="ชื่อ" />)}
            })(<StyledTextField
              fullWidth
              label={intl.formatMessage({ id : "app.firstName"})}
            />)}
          </FormItem>

        </div>
        <div className="loginTextField">
          <FormItem hasFeedback>
            {getFieldDecorator('profile.lastName', {
              rules: [
                {
                  required: true,
                  message: 'Please input your last name!'
                },
                {
                  message: 'Please input your last name!'
                }
              ]
              // })(<Input placeholder="นามสกุล" />)}
            })(<StyledTextField
              fullWidth
              label={intl.formatMessage({ id : "app.lastName"})}
            />)}
          </FormItem>
        </div>
        <div className="loginTextField">
          <FormItem hasFeedback>
            {getFieldDecorator('email', {
              normalize: (value) => (value ? value.replace(/[^\w!@#$%^&*()_+=\-."'<>?\\/]+/g, '') : '').toLowerCase(),
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!'
                },
                {
                  required: true,
                  message: 'Please input your E-mail!'
                }
              ]
            // })(<Input placeholder="อีเมล์" />)}
            })(<StyledTextField
              fullWidth
              label={intl.formatMessage({ id : "app.email"})}
            />)}
          </FormItem>
        </div>
        <div className="loginTextField">
          <FormItem hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please enter your password!'
                },
                {
                  validator: this.checkPassword
                }
              ]
            })(
              // <Input
              //   type="password"
              //   placeholder="รหัสผ่าน"
              //   onBlur={this.handleConfirmBlur}
              // />
              <StyledTextField
                onBlur={this.handleConfirmBlur}
                label={intl.formatMessage({ id : "app.password"})}
                type="password"
                fullWidth
              />
            )}
          </FormItem>
        </div>
        <div className="loginTextField" style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={this.state.signupLoading}
            style={{
              padding: '0 30px',
              marginBottom: 10,
              borderRadius: 0,
              width: '100%'
            }}
            // disabled={hasErrors(getFieldsError()) || !isFieldsTouched(
            //   ['email']
            // )}
          >
            {intl.formatMessage({ id : "app.signUp"})}
          </Button>
        </div>
        <div style={{textAlign: 'left'}}>
          <Link className="privacy-policy-login" to="/privacy-policy">Privacy Policy</Link>
        </div>
      </Form>
    )
  }
}
export default compose(
  Form.create(),
  withApollo,
  withRouter // TODO: should put router only inside auth wrapper
)(RegisterForm)
